import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-cmds',
  templateUrl: './cmds.component.html',
  styleUrls: ['./cmds.component.css']
})
export class CmdsComponent implements OnInit {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });
  public commands = [];

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get<any[]>("assets/data/cmd.json").subscribe(
      (data) => {
        this.commands = data;
      }
    );
  }

  accClick(t) {
    var element = (<HTMLElement> t);
    element.classList.toggle("active");
    var panel = (<HTMLElement> element.nextElementSibling);
    if (panel.style.display === "block") {
        panel.style.display = "none";
    } else {
        panel.style.display = "block";
    }
  }
}
