import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  private code = "";

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(
      (params) => {
        if (params['code'] != undefined) {
          let client = "458364885055700992"
          let secret = "WVqQSwLENgK8RXMqKy8UOzAV6QcExRAD"
          let redirect = "https://dbl.lejonathan.com/callback";
          let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
          let options = { headers: headers };
          let data = new URLSearchParams();
          data.set('client_id', client);
          data.set('client_secret', secret);
          data.set('grant_type', 'authorization_code');
          data.set('code', params['code']);
          data.set('redirect_uri', redirect);
          data.set('scope', 'identify guilds');
          this.http.post<any[]>("https://discordapp.com/api/oauth2/token", data.toString(), options).subscribe(
            (data) => {
              sessionStorage.setItem('discord_auth', JSON.stringify(data));
              window.location.href = "../home"
            }
          );
        } else {
          window.location.href = "../home"
        }
      }
    );
  }

}
