import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(private http: HttpClient) { }

  ngOnInit() {
    if (sessionStorage.getItem('discord_auth')) {
      let auth = JSON.parse(sessionStorage.getItem('discord_auth'));
      let loginBtn = document.getElementById("login");
      loginBtn.innerHTML = "Profile";
      loginBtn.style.cssText = "background-color: #ff9f00; color: #1d2127";
      let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + auth.access_token });
      let options = { headers: headers };
      this.http.get<any[]>("https://discordapp.com/api/users/@me", options).subscribe(
        (data) => {
          sessionStorage.setItem('discord_user', JSON.stringify(data));
        }
      );
    }
  }

  toggleNav() {
    let x = document.getElementById("nav");
    if (x.className === "navbar") {
        x.className += " responsive";
    } else {
        x.className = "navbar";
    }
  }
}
