import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });
  public updates = [];

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get<any[]>("assets/data/updates.json").subscribe(
      (data) => {
        this.updates = data;
      }
    );
    var element = (<HTMLElement> document.getElementById("1"));
    element.style.display = "block";
  }

  show(id) {
    var areas = document.getElementsByClassName('cArea');
    for (var i = 0, len = areas.length; i < len; i++) {
      (<HTMLElement> areas[i]).style.display = "none";
    }
    var element = (<HTMLElement> document.getElementById(id));
    element.style.display = "block";
  }

}
