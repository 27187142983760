import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });
  private apiUrl = "https://android21.space:8081/api/dbl/"
  private id = "";
  private players = [];
  public user = undefined;
  public player : any = {};
  public favorite = "https://i.imgur.com/Uu3WyEX.png";
  public characters = [];
  public nextDaily = "";

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    if (sessionStorage.getItem('discord_user')) {
      let user = JSON.parse(sessionStorage.getItem('discord_user'));
      this.user = user;
      this.id = user.id;
      this.favorite = "https://images.discordapp.net/avatars/" + user.id + "/" + user.avatar + ".png";
      this.http.get<any[]>(this.apiUrl + "player/" + user.id).subscribe(
        (data) => {
          this.player = data[0];
        },
        (error) => {},
        () => {
          this.player.level = Math.round(Math.sqrt(this.player.Experience));
          let d = new Date(this.player.Daily);
          let countDownDate = d.setHours(d.getHours() + 12);
          let x = setInterval(function() {
            let now = new Date().getTime();
            let distance = countDownDate - now;
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            let nextd = document.getElementById("nextd");
            if (nextd) {
              nextd.innerHTML = hours + ":" + minutes + ":" + seconds;
              if (distance < 0) {
                clearInterval(x);
                document.getElementById("nextd").innerHTML = "<span style='color:#ff9f00'>Ready</span>!";
              }
            }
          }, 1000);
          this.http.get<any[]>(this.apiUrl + "characters/" + user.id).subscribe(
            (data) => {
              this.characters = data;
            },
            (error) => {},
            () => {
              let tempList = [];
              this.http.get<any[]>("assets/data/characters.json").subscribe(
                (data) => {
                  for (let c of this.characters) {
                    let character = data.find(x => x.Id === c.ID);
                    if (this.player.favorite == c.ID) {
                      this.favorite = c.Image256;
                    }
                    c.Name = character.Name;
                    c.Image = character.Image256;
                    c.Level = Math.round(Math.sqrt(c.Experience));
                    c.Z_PowerLvl = 0;
                    if (c.Z_Power < 100) {
                      c.Z_PowerLvl = 0;
                    } else if (99 < c.Z_Power && c.Z_Power < 200) {
                      c.Z_PowerLvl = 0;
                    } else if (c.Z_Power > 199 && c.Z_Power < 400) {
                      c.Z_PowerLvl = 1;
                    } else if (c.Z_Power > 399 && c.Z_Power < 700) {
                      c.Z_PowerLvl = 2;
                    } else if (c.Z_Power > 699 && c.Z_Power < 1400) {
                      c.Z_PowerLvl = 3;
                    } else if (c.Z_Power > 1399 && c.Z_Power < 2400) {
                      c.Z_PowerLvl = 4;
                    } else if (c.Z_Power > 2399 && c.Z_Power < 5000) {
                      c.Z_PowerLvl = 5;
                    } else if (c.Z_Power > 4999 && c.Z_Power < 9999) {
                      c.Z_PowerLvl = 6;
                    } else if (c.Z_Power >= 9999) {
                      c.Z_PowerLvl = 7;
                    }
                    tempList.push(c);
                  }
                  this.characters = tempList;
                }
              );
            }
          );
        }
      );
    } else {
      window.location.href = "https://discordapp.com/api/oauth2/authorize?client_id=458364885055700992&redirect_uri=https%3A%2F%2Fdbl.lejonathan.com%2Fcallback&response_type=code&scope=identify";
    }
  };

  grabChar(id) {
    this.http.get<any[]>("assets/data/characters.json").subscribe(
      (data) => {
        return data.find(x => x.Id === id);
      }
    );
  }

  toClipBoard(c) {
    let el = document.createElement('textarea');
    el.value = "!g select " + c.Placement;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    alert(c.Name + " was copied to your clipboard. Paste it on discord to select them.")
  }

}
