import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from '../components/home/home.component';
import { CmdsComponent } from '../components/cmds/cmds.component';
import { GameComponent } from '../components/game/game.component';
import { SupportComponent } from '../components/support/support.component';
import { DiscordComponent } from '../components/discord/discord.component';
import { PlayerComponent } from '../components/player/player.component';
import { UpdatesComponent } from '../components/updates/updates.component';
import { CallbackComponent } from '../components/callback/callback.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'commands', component: CmdsComponent },
    { path: 'game', component: GameComponent },
    { path: 'donate', component: SupportComponent },
    { path: 'discord', component: DiscordComponent },
    { path: 'player', component: PlayerComponent },
    { path: 'news', component: UpdatesComponent },
    { path: 'callback', component: CallbackComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }
